import * as React from 'react';
import { PrimaryButton, QuaternaryButton } from '../../../components/button';
import { ManagementSoftware } from '../../../enums/ManagementSoftware';
import { FmsFormComponent } from './FmsFormComponent';
import { strings } from '../../../content/strings';
import { FormObject, IForm } from '../../../hooks/useForm';
import { Box } from "@mui/material";
import CustomSelect from '../../../core/form-components/CustomSelect';

export const _FMS = [
    ManagementSoftware[ManagementSoftware.storEDGE],
    ManagementSoftware[ManagementSoftware.SiteLink],
    ManagementSoftware[ManagementSoftware['Easy Storage Solutions']],
    ManagementSoftware[ManagementSoftware.DoorSwap],
    ManagementSoftware[ManagementSoftware.Hummingbird],
    ManagementSoftware[ManagementSoftware.WebSelfStorage],
    ManagementSoftware[ManagementSoftware.SsmCloud],
    ManagementSoftware[ManagementSoftware['Not listed']],
]

export interface IFmsForm extends FormObject {
    managementSoftware: number,
    externalFacilityId?: string | number | null,
    corporateCode?: string | null,
    locationCode?: string | null,
    corporateUsername?: string | null,
    corporatePassword?: string | null,
    baseUrl?: string | null,
    postalCode?: string | null,
}
export interface ISiteLinkForm extends FormObject {
    externalFacilityId?: string,
    locationCode?: string,
    corporateCode?: string,
    corporateUsername?: string,
    corporatePassword?: string,
    postalCode?: string,
    inMiles?: boolean
}

interface FmsFormProps<T extends FormObject> {
    form: IForm<T>,
    handleNext?: () => void,
    handleBack?: (validationFailed?: boolean) => void,
    handleSubmit?: (e: any) => Promise<boolean>,
    edit?: boolean,
    focus?: boolean,
    validationFailed?: boolean
}

/// FMSForm is used by CreateCompanyFlow and AddEditFacilityForm
export function FMSForm<T extends IFmsForm>({ form, handleBack, handleSubmit, validationFailed }: FmsFormProps<T>) {
    const fmsRef = React.useRef<{ fmsForm: any; fieldNames: string[]; }>({
        fmsForm: {},
        fieldNames: [],
    });

    const fmsProps = { ...form.inputProps.managementSoftware };
    const handleChangeFMS = (fms: PointerEvent) => {
        //@ts-ignore
        fmsProps.setValue(ManagementSoftware[fms.target?.value]);
    };

    const inputProps = form.inputProps.managementSoftware;

    const handleSubmitBack = async () => {
        handleBack!(!(await form.validateFields(...fmsRef.current.fieldNames)));
    };

    const backButton = () => {
        if (handleBack) {
            return <QuaternaryButton style={{ marginTop: "8px" }} type="button" fullWidth onClick={handleSubmitBack}>
                {strings.back}
            </QuaternaryButton>
        }
    };

    const submitButton = () => {
        if (handleSubmit) {
            return (
                <PrimaryButton style={{ marginTop: "8px" }} fullWidth onClick={handleSubmit}>
                    {strings.submit}
                </PrimaryButton>
            )
        }
    };


    const doLaterButton = () => {
        if (validationFailed) {
            return (
                <PrimaryButton style={{ marginTop: "8px" }} fullWidth onClick={async (e) => {
                    e.preventDefault();  // prevent default form submission

                    await form.setFormValuesAsync((prev) => ({
                        ...prev,
                        managementSoftware: 0,
                        externalFacilityId: "",
                        corporateCode: null,
                        corporatePassword: null,
                        corporateUsername: null,
                        locationCode: null,
                        baseUrl: ""
                    }))

                    document.getElementsByTagName("button")

                    const formEl = e.target.form
                    if (formEl) {
                        const buttons = formEl.getElementsByTagName("button")
                        buttons[buttons.length - 1].click()
                    }
                }}>
                    DO THIS LATER
                </PrimaryButton>
            )
        }
    }

    return (
        <>
            <CustomSelect value={
                ManagementSoftware[form?.values?.managementSoftware || 0]}
                handleChange={handleChangeFMS as any}
                values={_FMS}
            />
            <FmsFormComponent
                form={form}
                fmsRef={fmsRef}
                fms={inputProps.value as ManagementSoftware} />
            <Box sx={{ mb: 2, display: "flex", gap: "16px" }}>
                {backButton()}
                {submitButton()}
                {doLaterButton()}
            </Box>
        </>
    );
};
