import React, { useEffect } from "react";
import ActivateSerialCodePage from "../pages/activate-serial-code/ActivateSerialCodePage";
import ApiSettingsPage from "../pages/api-settings/ApiSettingsPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import FacilityListingPage from "../pages/facility-listing/FacilityListingPage";
import FacilityTransferPageContainer from "../pages/approve-facility-transfer/FacilityTransferPageContainer"
import ForgotPasswordPage from "../pages/forgot-password/ForgotPasswordPage"
import LandingPage from "../pages/landing-page/LandingPage";
import HowItoWorksPage from "../pages/how-it-works/HowItoWorksPage"
import LoginPage from "../pages/login/LoginPage"
// import NewDashboardPage from "../pages/dashboard/NewDashboardPage";
import ProcessLockReturnsPage from "../pages/process-returned-locks/ProcessLockReturnsPage";
import ResetPasswordPage from "../pages/reset-password/ResetPasswordPage"
import SerialCodesPage from "../pages/serial-codes/SerialCodesPage";
import InitiateFacilityTransferPage from "../pages/transfer-facilities/InitiateFacilityTransferPage"
import UsersListingPage from "../pages/users-listing/UsersListingPage";
import UserProfilePage from "../pages/user-profile-update/UserProfilePage";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useLocation,
    useNavigate,
} from "react-router-dom";
import AuthorizedRedirect from "../middleware/AuthorizedRedirect";
import UnauthorizedRedirect from "../middleware/UnauthorizedRedirect";
import Role from "../enums/Role";
import ExpiredResetLinkPage from "../pages/expired-reset-link/ExpiredResetLinkPage";
import ClientCallCenterPage from "../pages/client-call-center/ClientCallCenterPage";
import CompanySubdomainsPage from "../pages/company-subdomains/CompanySubdomainsPage";
import UrlParamErrorMiddleware from "../middleware/UrlParamErrorMiddleware";
import CreateCompanyFlowPage from "../pages/create-company-flow/CreateCompanyFlowPage";
import InventoryPage from "../pages/inventory/Inventory";
import UserLogs from "../pages/user-logs/UserLogs";
import CompanyListingPage from "../pages/company-listing/CompanyListingPage";
import CompanyDetailsPage from "../pages/company-details/CompanyDetailsPage";
import FreeSerialCodesPage from "../pages/free-serial-codes/FreeSerialCodesPage";
import LeadForm from "../pages/lead-form/LeadForm"
import ThankYou from "../pages/thank-you/ThankYou"
import VendorPage from "../pages/vendor/VendorPage"
import UpdatedTermsOfUsePage from "../pages/legal/terms-of-use/UpdatedTermsOfUse"
import PrivacyPolicyPage from "../pages/legal/privacy-policy/PrivacyPolicyPage"
import Permission from "../enums/Permission";
import RolesAndPermissions from "../pages/roles-and-permissions/RolesAndPermissionsPage"
import IntegrationsPage from "../pages/integrations/IntegrationsPage"
import RoleSettings from "../pages/roles-settings/RoleSettings"
import TermsOfUsePage from "../pages/legal/terms-of-use/TermsOfUsePage"
import AutoNotifications from "../pages/auto-notifications/AutoNotifications"
import CustomMessage from "../pages/custom-message/CustomPage"
import SsoRequestPage, { SsoLoginSuccessPage } from "../pages/sso-request-page/SsoRequestPage";
import HowToPage from "../pages/how-to/HowToPage";
import { useLogout } from "../hooks/useLogout";
import SubTermsOfUsePage from "../pages/legal/sub-terms-of-use/SubTermsOfUse";

const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

const authorizedRoutes: [
    string,
    () => JSX.Element | null,
    { roles?: number[], permission?: Permission } | null
][] = [
        ["/Users/Dashboard/", DashboardPage, null],
        ["/Users/GetLogs", UserLogs, {
            roles: [
                Role["Site Admin"],
                Role["Super Admin"],
            ]
        }],
        ["/Company", CompanyListingPage, {
            roles: [
                Role["Super Admin"],
                Role["Site Admin"],
                Role["Site Personnel"]
            ]
        }],
        ["/SerialCodes_Locks/SerialCodeListingByCompany", SerialCodesPage, {
            permission: Permission.Locks,
            roles: [
                Role["Owner"],
                Role.Admin,
                Role.Personnel,
                Role["Restricted Personnel"],
                Role["Site Admin"],
                Role["Super Admin"],
            ]
        }],
        ["/SerialCodes_Locks/Reports", ProcessLockReturnsPage, {
            permission: Permission["Lock Returns"],
            roles: [
                Role["Owner"],
                Role.Admin,
                Role.Personnel,
                Role["Restricted Personnel"],
            ]
        }],
        ["/Company/FacilityListing", FacilityListingPage, {
            permission: Permission.Facilities,
            roles: [
                Role["Owner"],
                Role.Admin,
            ]
        }],
        ["/Users/ClientUserListing", UsersListingPage, {
            permission: Permission.Users,
            roles: [
                Role["Owner"],
                Role.Admin,
                Role["Site Admin"],
                Role["Super Admin"],
                Role["Site Personnel"],
            ]
        }],
        ["/Users/DaVinciLock", UsersListingPage, {
            roles: [
                Role["Site Admin"],
                Role["Super Admin"],
            ]
        }],
        ["/SerialCodes_Locks/Redeem", ActivateSerialCodePage, {
            permission: Permission["Activate Locks"],
            roles: [
                Role["Owner"],
                Role.Admin,
                Role.Personnel,
                Role["Restricted Personnel"],
            ]
        }],
        // ["/Users/Dashboard/2", NewDashboardPage, null],
        ["/Users/Profile", UserProfilePage, null],
        ["/Company/Api", ApiSettingsPage, {
            permission: Permission.ApiSettings,
            roles: [
                Role["Owner"],
                Role.Admin,
                Role["Super Admin"],
            ]
        }],
        ["/Company/InitiateFacilityTransfer/:id", InitiateFacilityTransferPage, {
            permission: Permission["Transfer Facility"],
            roles: [
                Role["Super Admin"],
                Role["Owner"],
                Role.Admin,
            ]
        }],
        ["/Company/CompanyDetails", CompanyDetailsPage, {
            roles: [
                Role["Super Admin"],
                Role["Site Admin"],
                Role["Site Personnel"],
                Role["Site Call Center Agent"]
            ]
        }],
        ["/Users/ClientCallCenter", ClientCallCenterPage, {
            roles: [
                Role["Call Center Agent"]
            ]
        }],
        ["/Company/Subdomains", CompanySubdomainsPage, {
            permission: Permission.Subdomains,
            roles: [
                Role["Owner"],
                Role.Admin,
            ]
        }],
        ["/Company/Inventory", InventoryPage, {
            permission: Permission.Inventory,
            roles: [
                Role["Owner"],
                Role.Admin,
                Role["Site Admin"],
                Role["Super Admin"],
                Role["Restricted Personnel"],
                Role.Personnel,
            ]
        }],
        ["/SerialCodes_Locks/FreeCodes", FreeSerialCodesPage, {
            roles: [
                Role["Super Admin"],
                Role["Site Admin"],
                Role["Manufacturing QA"],
            ]
        }],
        ["/SerialCodes_Locks/Vendor", VendorPage, {
            roles: [
                Role["Vendor"],
            ]
        }],
        ["/Account/UpdatedTermsOfUse/", UpdatedTermsOfUsePage, null],
        // ["/Account/PrivacyPolicy", PrivacyPolicyPage, null],
        ["/Company/RolesAndPermissions", RolesAndPermissions, {
            roles: [
                Role["Owner"],
                Role.Admin,
            ]
        }],
        ["/Company/RolesSettings", RoleSettings, {
            roles: [
                Role["Owner"],
                Role.Admin,
            ]
        }],
        ["/Company/Integrations", IntegrationsPage, {
            roles: [
                Role["Owner"],
                Role.Admin,
            ]
        }],
        ["/Company/AutoNotifications", AutoNotifications, {
            roles: [
                Role["Owner"],
                Role.Admin,
            ]
        }],
        ["/Company/CustomMessage", CustomMessage, {
            roles: [
                Role["Owner"],
                Role.Admin,
            ]
        }],
    ]

const unauthorizedRoutes: [string, (props?: any) => JSX.Element | null][] = [
    ["/", LandingPage],
    ["/Account/HowItWorks", HowItoWorksPage],
    ["/Account/Login", LoginPage],
    ["/Account/ForgotPassword", ForgotPasswordPage],
    ["/Account/CreateCompany", CreateCompanyFlowPage],
    ["/Account/ResetUserPassword/", ResetPasswordPage],
    ["/Account/ExpiredResetLink/", ExpiredResetLinkPage],
    ["/Account/GettingStarted/", LeadForm],
    ["/Account/ThankYou", ThankYou],
    // ["/Account/PrivacyPolicy", PrivacyPolicyPage],
]

const AppRoutes = () => {
    return (
        <>
            <ScrollToTop />
            <Routes>
                {unauthorizedRoutes.map(([path, Component,]) => (
                    <Route key={path} path={path}
                        element={
                            <AuthorizedRedirect>
                                <UrlParamErrorMiddleware>
                                    <Component />
                                </UrlParamErrorMiddleware>
                            </AuthorizedRedirect>
                        }
                    />
                ))}
                {authorizedRoutes.map(([path, Component, options]) => (
                    <Route key={path} path={path}
                        element={
                            <UnauthorizedRedirect
                                permission={options?.permission}
                                roles={options?.roles || null}
                            >
                                <UrlParamErrorMiddleware>
                                    <Component />
                                </UrlParamErrorMiddleware>
                            </UnauthorizedRedirect>
                        }
                    />
                ))}
                <Route path="/Account/FacilityTransfer/"
                    element={<FacilityTransferPageContainer />}
                />

                <Route path="/Account/PrivacyPolicy"
                    element={
                        <PrivacyPolicyPage />
                    }
                />

                <Route path="/Account/TermsOfUse"
                    element={<TermsOfUsePage />}
                />
                <Route path="/Account/SubscriptionTermsOfUse"
                    element={<SubTermsOfUsePage />}
                />

                <Route path="/Account/HowTo"
                    element={<HowToPage />}
                />
                <Route path="*" element={<RouteDoesNotExist />} />

                <Route path="/auth/sso"
                    element={<SsoRequestPage />}
                />

                <Route path="/mobile/login"
                    element={<SsoLoginSuccessPage />}
                />

                <Route path="/logout"
                    element={<LogoutPage />}
                />
            </Routes>
        </>
    )
}

const LogoutPage = () => {
    const { logout } = useLogout()
    const navigate = useNavigate()

    useEffect(() => {
        const logoutUser = async () => {
            await logout()
            navigate("/Account/Login")
        }

        logoutUser()
    }, [])

    return null
}

const RouteDoesNotExist = () => {
    return (
        <Navigate to="/" replace />
    )
}

export default AppRoutes;
