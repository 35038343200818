import * as yup from "yup";
import agents from "../api/agent";
import LockStatus from "../enums/LockStatus";
import { ICreateCompany } from "../models/ICreateCompanyForm";
import { ICompanyUser } from "../models/ICompanyUser";
import { strings } from "../content/strings";
import { ManagementSoftware } from "../enums/ManagementSoftware";
import StringTemplate from "../utils/stringTemplateMethods";
import { messageLimit } from "../enums/MessagesTemplateValue";

const EmailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

export const emailField = yup.string()
    .matches(EmailRegex, strings.mustBeValidEmail)
    .required(strings.required(strings.email));

const passwordValidator = yup.string().required(strings.required(strings.password));
const passwordConfirmation = yup.string().oneOf([yup.ref("password"), null], strings.passwordsMustMatch);
export const zipField = yup.string().required(strings.required(strings.zip));
const firstNameField = yup.string().required(strings.required(strings.firstName));
const lastNameField = yup.string().required(strings.required(strings.lastName));
const facilityNameField = yup.string().required(strings.required(strings.facilityName));

export const phoneNumberValidator = yup.string()
    // must have 10 digits and allows for characters: -, (, ), and whitespace
    .matches(/^[^a-zA-Z]+$/, strings.mustBeValidPhoneNumber)
    .required(strings.required(strings.phoneNumber));

const fmsValidation = {
    managementSoftware: yup.number().min(0, strings.requiredManagementSoftware),
    externalFacilityId: yup.string().nullable()
        .when("managementSoftware", {
            is: (managementSoftware: ManagementSoftware) => {
                return managementSoftware == ManagementSoftware.storEDGE
                    || managementSoftware == ManagementSoftware["Easy Storage Solutions"]
                    || managementSoftware == ManagementSoftware.DoorSwap
                    || managementSoftware == ManagementSoftware.Hummingbird
                    || managementSoftware == ManagementSoftware.SsmCloud;
            },
            then: yup.string().nullable().required(strings.required("facility id"))
        })
        .when("managementSoftware", {
            is: (managementSoftware: ManagementSoftware) => {
                return managementSoftware == ManagementSoftware.WebSelfStorage
            },
            then: yup.string().nullable().required(strings.required(strings.entityNumber))
        }),
    corporateCode: yup.string().nullable()
        .when("managementSoftware", {
            is: (managementSoftware: ManagementSoftware) => {
                return managementSoftware == ManagementSoftware.SiteLink;
            },
            then: yup.string().nullable().required(strings.required(strings.validators.corporate))
        }),
    locationCode: yup.string().nullable()
        .when("managementSoftware", {
            is: (managementSoftware: ManagementSoftware) => {
                return managementSoftware == ManagementSoftware.SiteLink;
            },
            then: yup.string().nullable().required(strings.required(strings.validators.locationCode))
        }),
    corporateUsername: yup.string().nullable()
        .when("managementSoftware", {
            is: (managementSoftware: ManagementSoftware) => {
                return managementSoftware == ManagementSoftware.SiteLink
                    || managementSoftware == ManagementSoftware.DoorSwap;
            },
            then: yup.string().nullable().required(strings.required(strings.validators.userName))
        }),
    corporatePassword: yup.string().nullable()
        .when("managementSoftware", {
            is: (managementSoftware: ManagementSoftware) => {
                return managementSoftware == ManagementSoftware.SiteLink
                    || managementSoftware == ManagementSoftware.DoorSwap
                    || managementSoftware == ManagementSoftware.SsmCloud;
            },
            then: yup.string().nullable().required(strings.required(strings.password))
        }),
    baseUrl: yup.string().nullable()
        .when("managementSoftware", {
            is: (managementSoftware: ManagementSoftware) => {
                return managementSoftware == ManagementSoftware.SsmCloud;
           },
            then: yup.string().nullable().required(strings.required(strings.baseUrl))
        }),
}

export const fmsValidator = yup.object().shape({
    ...fmsValidation
})

export const siteLinkValidator = yup.object().shape({
    ...fmsValidation,
    postalCode: yup.string().nullable(),
})

export const accountValidator = yup.object().shape({
    email: emailField,
    password: passwordValidator
});

export const emailValidator = yup.object().shape({
    email: emailField,
});
export const customTextValidator = yup.object().shape({
    template: yup.string()
        .required("Field is required")
        .test((value: string | undefined, testContext: yup.TestContext) => {
            // the context object gets populated on validation, so initially it's empty and throws a type  error!
            //@ts-ignore
            let textLength = messageLimit[testContext?.options?.context?.message]
            if (StringTemplate.templateCount(value as string) > textLength) {
                return testContext.createError({ message: `Length exceeded maximum of ${textLength} characters` })
            }
            // TODO: return false is no error, or return a string error if there is an error
            const result = StringTemplate.validateTemplate(value || "", ["name", "company", "facility", "unlockCodes"])
            if (result) {
                return true;
            }
            // return the error returned from StringTemplate.validateTemplate
            return testContext.createError({ message: "There is a formatting error" })
        }),
});

export const transferFacilityValidator = yup.object().shape({
    email: emailField.test(strings.validators.facilityTransfer.key,
        strings.validators.facilityTransfer.validation, function () {
            let allowed = this.parent.users.find((u: ICompanyUser) => u.email === this.parent.email);
            if (allowed !== undefined) {
                return false
            }
            return true;
        })
})

export const deriveRequiredFields = (validator: yup.ObjectSchema<any>) => {
    return (Object.entries(validator.fields) as [string, any]).reduce((acc, [k, v]) => {
        return [
            ...acc,
            ...(v?.exclusiveTests?.required || v?.exclusiveTests?.min ? [k] : [])
        ]
    }, [])
}

export const resetPasswordValidator = yup.object().shape({
    password: passwordValidator,
    confirmPassword: passwordConfirmation,
})

export const createCompanyValidator = yup.object().shape({
    firstName: firstNameField,
    lastName: lastNameField,
    companyName: yup.string().required(strings.required(strings.companyName)),
    companyUrl: yup.string().required(strings.required(strings.companyUrl)).test(
        async (value: string | undefined, testContext: yup.TestContext) => {
            if (!value) {
                return testContext.createError({ message: strings.required(strings.companyUrl) })
            }
            const res = await agents.Company.validateCompanyUrl(value)
            if (!res.success || !res.data) {
                return testContext.createError({ message: res.error })
            }
            return true;
        }),
    email: emailField.test(
        async (value: string | undefined, testContext: yup.TestContext) => {
            if (!value) {
                return testContext.createError({ message: strings.required(strings.email) })
            }
            const res = await agents.Company.validateCompanyEmail(value)
            if (!res.success || !res.data) {
                return testContext.createError({ message: strings.emailInvalidOrInUse })
            }
            return true;
        }),
    phoneNumber: phoneNumberValidator,
    street: yup.string()
        .min(4, strings.streetValidation)
        .required(strings.required(strings.street)),
    city: yup.string().required(strings.required(strings.city)),
    state: yup.string().required(strings.required(strings.state)),
    zip: zipField,
    facilityName: facilityNameField,
    facilityBrandName: yup.string().required(strings.required(strings.facilityBrandName)),
    facilityUrl: yup.string().required(strings.required(strings.facilityUrl)),
    facilityStreet: yup.string()
        .min(4, strings.streetValidation)
        .required(strings.required(strings.street)),
    facilityCity: yup.string().required(strings.required(strings.city)),
    facilityState: yup.string().required(strings.required(strings.state)),
    facilityZip: zipField,
    ...fmsValidation,
    usesFms: yup.boolean().notOneOf([null]),
    acceptedTerms: yup.boolean().notOneOf([null]),
    password: passwordValidator,
    confirmPassword: yup.string().required(strings.required(strings.confirmPassword)).oneOf([yup.ref("password")], strings.passwordsMustMatch),
});

export const searchLockBySerialCodeValidator = yup.object().shape({
    serialCode: yup.string().length(8, strings.validators.serialCodelength).required(strings.serialCode),
})

export const acceptTransferValidator = yup.object().shape({
    facilityName: facilityNameField,
    facilityURL: yup.string(),
    email: yup.string().email(strings.mustBeValidEmail).nullable(true),
    phoneNumber: phoneNumberValidator.required(strings.required(strings.phoneNumber)),
    phoneNumberCustomerService: yup.string(),
});

export const activateLockValidator = yup.object().shape({
    facilityID: yup.number().required(strings.required(strings.facilityID)),
    serialCode: yup.string().length(8, strings.validators.serialCodelength).required(strings.serialCode),
})

export const apiKeyValidator = yup.object().shape({
    allFacilities: yup.boolean().nullable(),
    facilityIDs: yup.array().nullable(),
    id: yup.number().nullable(),
    isActive: yup.boolean(),
    key: yup.string().nullable(),
    name: yup.string(),
    secret: yup.string().nullable(),
})

export const lockModelValidator = yup.object().shape({
    lockID: yup.number().required(),
    companyID: yup.number(),
    facilityID: yup.number(),
    serialCode: yup.string(),
    unlockCode: yup.string().length(4, strings.validators.unlockCodelength).required(strings.validators.unlockCodeReq),
    unitNumber: yup.string().nullable().when("secondaryLockStatus", {
        is: (status: LockStatus) => {
            if (status == LockStatus["On Dropbox"]) return false
            return !LockStatus.isNotAssigned(status)
        },
        then: yup.string().nullable().required(strings.required(strings.unitNumber)),
        otherwise: yup.string().nullable(),
    }),
    isActive: yup.boolean(),
    statusName: yup.string(),
    secondaryStatusName: yup.string(),
    lockStatusID: yup.number(),
    secondaryLockStatus: yup.number(),
});
export const inventoryLockModelValidator = yup.object().shape({
    lockID: yup.number().required(),
    companyID: yup.number(),
    serialCode: yup.string(),
    unlockCode: yup.string().length(4, strings.validators.unlockCodelength).required(strings.validators.unlockCodeReq),
    unitNumber: yup.string().nullable().when("secondaryLockStatus", {
        is: (status: LockStatus) => {
            return !LockStatus.isNotAssigned(status)
        },
        then: yup.string().nullable().required(strings.required(strings.unitNumber)),
    }),
    isActive: yup.boolean(),
    statusName: yup.string(),
    secondaryStatusName: yup.string(),
    lockStatusID: yup.number(),
    secondaryLockStatus: yup.number(),
});

export const facilityModelValidator = yup.object().shape({
    facilityName: yup.string().min(1),
    facilityDisplayName: yup.string().nullable(),
    companyDisplayName: yup.string().nullable(),
    companyName: yup.string().nullable(),
    facilityURL: yup.string().nullable(),
    no_Of_Units: yup.number().nullable().typeError(strings.validators.mustBeNum),
    no_of_locks: yup.number().nullable().typeError(strings.validators.mustBeNum),
    email: yup.string().nullable()
        .when({
            is: (email: string) => !!email?.length,
            then: yup.string().matches(EmailRegex, strings.validators.mustBeVaildEmail).nullable()
        }),
    phoneNumber: phoneNumberValidator,
    phoneNumberCustomerService: yup.string().nullable(),
    street: yup.string().nullable().min(4, strings.min(strings.street, 4)).required(strings.required(strings.street)),
    city: yup.string().nullable().required(strings.required(strings.city)),
    state: yup.string().nullable().required(strings.required(strings.state)),
    zip: yup.string().nullable().required(strings.required(strings.zip)),
    lat: yup.number().required(strings.required(strings.lat)),
    lng: yup.number().required(strings.required(strings.lng)),
    ...fmsValidation
});

export const storEdgeValidator = yup.object().shape({
    externalFacilityId: yup.string().nullable().required(strings.required(strings.validators.facilityID)),
})

export const userModelValidator = (initialValues: ICompanyUser) => yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    facilityDisplayName: yup.string(),
    roleID: yup.number().min(1, strings.validators.userRole),
    facility: yup.string(),
    email: emailField.test(
        async (value: string | undefined, testContext: yup.TestContext) => {
            if (!value) {
                return testContext.createError({ message: strings.required(strings.email) })
            }
            if (initialValues.email === value) {
                return true
            }
            const res = await agents.Users.validateUniqueEmail(value)
            if (!res.data) {
                return testContext.createError({ message: strings.validators.uniqueEmail });
            }
            if (!res.success) {
                return testContext.createError({ message: res.error || strings.somethingWentWrong });
            }
            return true;
        }),
    facilityIDs: yup.mixed().nullable(),
});

export const subdomainValidator = yup.object().shape({
    displayName: yup.string().required(strings.required(strings.displayName)),
    subdomainUrl: yup.string().required(strings.required(strings.subdomainUrl)).max(20, strings.max(strings.subdomainUrl, 20)).matches(/^[a-z0-9](?:[a-z0-9\-]{0,18}[a-z0-9])?/, strings.subdomainUrlRegex),
    facilities: yup.mixed().nullable(),
    logo: yup.string().nullable(),
});

export const roleSettingsValidator = yup.object().shape({
    name: yup.string().required(strings.required(strings.permissionName)),
    //accessLevel: yup.number().required(strings.required(strings.permissionAccess)).max(2, strings.max(strings.permissionAccess, 2)),
})

export const addUserValidator = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    facilityDisplayName: yup.string().required(),
    role: yup.string().required(),
    facility: yup.string().required(),
    street: yup.string().required(),
    zip: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    email: emailField.test(
        async (value: string | undefined, testContext: yup.TestContext) => {
            if (!value) {
                return testContext.createError({ message: strings.required(strings.email) })
            }
            const res = await agents.Users.validateUniqueEmail(value)
            if (!res.success) {
                return testContext.createError({ message: res.error })
            }
            return true;
        }),
    facilityIDs: yup.mixed().nullable().required(),
});

export const editUserProfileValidator = yup.object().shape({
    firstName: firstNameField.max(100, strings.max(strings.firstName, 100)),
    lastName: lastNameField.max(100, strings.max(strings.lastName, 100)),
});

export const editEmailValidator = yup.object().shape({
    email: emailField,
    newEmail: emailField.test(
        async (value: string | undefined, testContext: yup.TestContext) => {
            if (!value) {
                return testContext.createError({ message: strings.required(strings.email) })
            }
            const res = await agents.Users.validateUniqueEmail(value)
            if (!res.success) {
                return testContext.createError({ message: res.error })
            }
            return true;
        }),
    confirmEmail: emailField.oneOf([yup.ref('newEmail'), null], strings.validators.emailMatch),
});

export const freeLocksValidator = yup.object().shape({
    noOfNewLocks: yup.number().min(1, "Have to generate at least one lock"),
    color: yup.string().required(),
    manufacturer: yup.string().required(),
})
