import { IBaseResponse } from "./IBaseResponse";

export interface IAddress {
    street?: string,
    city?: string,
    state?: string,
    zip?: string,
    lat?: number,
    lng?: number,
}

export interface FacilityDto extends IAddress {
    apiFacilityID?: string,
    facilityID?: number,
    facilityName?: string,
    facilityDisplayName?: string,
    facilityURL?: string,
    companyName?: string,
    companyDisplayName?: string,
    companyID?: number,
    companySubdomainID?: number,
    no_Of_Units?: number,
    no_of_locks?: number,
    email?: string,
    phoneNumber?: string,
    phoneNumberCustomerService?: string,
    isActive?: boolean,
    includedInAPI?: boolean,
    managementSoftware: number,
    externalFacilityId?: string | number | null,
    corporateCode?: string | null,
    locationCode?: string | null,
    corporateUsername?: string | null,
    corporatePassword?: string | null,
    baseUrl?: string | null,
    subDomain?: string,
}

export const blankFacilityForm = () => ({
    apiFacilityID: "",
    facilityName: "",
    facilityDisplayName: "",
    facilityURL: "",
    companyName: "",
    companyDisplayName: "",
    no_Of_Units: 0,
    no_of_locks: 0,
    email: "",
    phoneNumber: "",
    phoneNumberCustomerService: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    isActive: true,
    includedInAPI: true,
    corporateCode: "",
    locationCode: "",
    corporateUsername: "",
    corporatePassword: "",
    externalFacilityId: "",
    baseUrl: "",
    managementSoftware: 0,
})

export interface FacilityResponse extends IBaseResponse {
    data: FacilityDto
}
