import agents, { cancelTokenSources, expireToken } from '../../api/agent';
import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ILoggedInUser } from "../../models/ILoggedInUser"
import { loadUserStore } from './loadUserStore';
import { strings } from '../../content/strings';
import { ActionTypes } from './ActionTypes';
import * as Tracker from "../../utils/errorHandling"
import environment from '../../utils/environment';

const delay = (ms: number): Promise<any> => {
    return new Promise((resolve: TimerHandler) => setTimeout(resolve, ms));
}

interface IAuth {
    loggedInUser: ILoggedInUser | null,
    /** - 'awaiting' is the initial status.
     * This is important on entry of the app for the auth middleware in
     * answering the questions: "do I redirect or no?"
     * - 'fetched' means the app is loaded and has concluded if the user is logged in. */
    status: 'awaiting' | 'fetched',
    /** If error is not falsy, then we know something went wrong with authentication.
     * Therefore, we do not need a 'failed' or 'errored' status */
    error?: string,
    /** pending status is used for things like loaders on buttons. */
    pending?: boolean,
}

const initialState: IAuth = {
    /** The first thing the app does is checked to see if you are logged in.
     * 'awaiting' status means the app hasn't verified this yet and is not loaded yet. */
    status: 'awaiting',
    loggedInUser: null,
}

export const getUser = (store: any): ILoggedInUser | null => {
    return (store.getState() as RootState)?.auth?.loggedInUser || null
}

export const getUserID = (store: any) => {
    return getUser(store)?.userID || 0
}

const fetchLoggedInUser = async (store: any, loadStore: boolean) => {
    const response = await agents.Account.getCurrentUser();

    if (response.data) {
        loadStore && loadUserStore(response.data, (store.getState() as RootState)?.auth?.loggedInUser, store.dispatch, true)
        Tracker.setUser(response.data)

        return response.data;
    }
}

export const fetchLoggedInUserAsync = createAsyncThunk(
    'Auth/fetchLoggedInUserAsync',
    async (_, store) => {
        try {
            return await fetchLoggedInUser(store, true)
        } catch (err) {
            await delay(2000)
            return fetchLoggedInUser(store, true)
        }
    }
)

export const fetchUpdatedLoggedInUserAsync = createAsyncThunk(
    'Auth/fetchLoggedInUserAsync',
    async (previousState: ILoggedInUser & { forceUpdate?: boolean }, store) => {
        const response = await agents.Users.getUpdatedCurrentUser();
        const nextState = response.data
        if (previousState.forceUpdate ||
            (nextState && (nextState.roleID !== previousState.roleID))
        ) {
            // We set the access token just by calling getUpdatedCurrentUser
            // we need to refetch for redux now with loadUserStore
            // reload user store with new user roleID
            loadUserStore(response.data, previousState, store.dispatch)
        }
        return nextState
    }
)

export const fetchLoggedInUserNoLoad = createAsyncThunk(
    'Auth/fetchLoggedInUserAsync',
    async (_, store) => {
        try {
            return await fetchLoggedInUser(store, false)
        } catch (err) {
            await delay(2000)
            return fetchLoggedInUser(store, false)
        }
    }
)

export const loginAsync = createAsyncThunk(
    'Auth/loginAsync',
    async (loginForm: { email: string, password: string }, store) => {
        const response = await agents.Auth.login(loginForm);
        if (response.success) {
            loadUserStore(response.data, getUser(store), store.dispatch)
            Tracker.setUser(response.data!)

            return response.data;
        }
        return store.rejectWithValue(response.error || strings.somethingWentWrong);
    }
)

export const getAuthUserFromUid = createAsyncThunk(
    'Auth/getAuthUserFromUid',
    async (uid: string, store) => {
        const response = await agents.Auth.getAuthUserFromUid(uid);
        if (response.success) {
            loadUserStore(response.data, getUser(store), store.dispatch)
            Tracker.setUser(response.data!)

            return response.data;
        }
        return store.rejectWithValue(response.error || strings.somethingWentWrong);
    }
)

/** When using combineReducer, we can dispatch a single action for every reducer slice to receive */
export const logoutUser = () => {
    expireToken();
    Tracker.setUser(null)

    cancelTokenSources.forEach((source: any) => source.abort());

    /** we are assuming the logout is successful and so we are not awaiting this */
    agents.Account.logout();

    return {
        type: ActionTypes.LOGOUT
    }
}

export const updateLoggedInUser = createAsyncThunk('Auth/UpdateLoggedInUser', async (action: ILoggedInUser) => { return action });

export const authSlice = createSlice({
    name: "Auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateLoggedInUser.fulfilled, (state, action) => {
                return { ...state, loggedInUser: action.payload ? action.payload : null }
            })
            .addMatcher(
                isAnyOf(fetchLoggedInUserAsync.rejected, loginAsync.rejected, getAuthUserFromUid.rejected),
                (_, action: PayloadAction<any>) => {
                    return { status: 'fetched', loggedInUser: null, error: action.payload }
                })
            .addMatcher(
                isAnyOf(fetchLoggedInUserAsync.fulfilled, loginAsync.fulfilled, getAuthUserFromUid.fulfilled),
                (_, action: PayloadAction<any>) => {
                    return { status: 'fetched', loggedInUser: action.payload ? action.payload : null }
                })
            .addMatcher(
                isAnyOf(fetchLoggedInUserAsync.pending, loginAsync.pending, getAuthUserFromUid.pending),
                (state) => {
                    state.pending = true
                })
            .addMatcher(({ type }: { type: string }) => type === ActionTypes.LOGOUT,
                (state: any) =>
                {
                    localStorage.setItem("bannerClosed", "false")
                    return { ...state, loggedInUser: null }
                }    
            )
            .addDefaultCase((state) => {
                return state;
            })
    }
})

export const getCurrentUser = (state: RootState) => state.auth
export const getAuthError = (state: RootState) => state.auth.error
export const getAuthPending = (state: RootState) => state.auth.pending
