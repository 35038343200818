import agents from "../../../api/agent";
import Input from "../../../core/form-components/Input";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { emailValidator } from "../../../validators/validators"
import { Card } from "../../../core/surfaces/Card";
import { Form } from "../../../core/form-components/Form";
import { H2, FormErrorText, PMedium } from "../../../core/brand/typography";
import { IAccount } from "../../../models/models";
import { PrimaryButton } from "../../../components/button";
import { strings } from "../../../content/strings";
import { useForm } from "../../../hooks/useForm";
import {isEmailInDomainMap } from "../../../hooks/useLogin";
import OktaLoginDialog from "../../../core/common/OktaLoginDialog";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";

const ForgotPasswordForm = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const initialValues: IAccount["forgotPassword"] = { email: "" }
    const [redirectToOktaLogin, setRedirectToOktaLogin] = useState(false);
    const { displayToast } = useContext(ToastContext);

    const submitAction = async (formValues: IAccount["forgotPassword"], setFormValues: (arg0: IAccount["forgotPassword"]) => any) => {
        const { email } = formValues;
        const isOktaLogin = isEmailInDomainMap(email);
        if (isOktaLogin) {
            const response = await agents.Auth.getUserByEmail(email);
            if (response.success && response.data.sso) {
                setRedirectToOktaLogin(true);
                return;
            }
            else {
                displayToast({ type: ToastType.Error, text: "something went wrong" })
                return;
            }
        }
        const result = await agents.Auth.forgotPassword(formValues.email);
        if (result.success) {
            setSuccess(true);
            setMessage(strings.passwordResetLinkConfirmation);
        } else {
            setSuccess(false);
            setErrorMessage(result.error! || strings.somethingWentWrong);
        }
    }

    const form = useForm<IAccount["forgotPassword"]>(
        initialValues, submitAction, emailValidator
    );

    return (
        success ?
            <SuccessCard>
                <PMedium>{message}</PMedium>
            </SuccessCard>
            :
            <FormCard>
                <Form>
                    {OktaLoginDialog({ redirectToOktaLogin, setRedirectToOktaLogin, login: false })}
                    <TitleContainer>
                        <H2>{strings.forgotPassword}</H2>
                    </TitleContainer>
                    <Input {...form.inputProps.email} required label={strings.email} />
                    <PrimaryButton fullWidth onClick={form.handleSubmit}>{strings.submit}</PrimaryButton>
                </Form>
                <ErrorMessage>{errorMessage}</ErrorMessage>
            </FormCard>
    );
};

const FormCard = styled(Card)({ width: "360px", position: "relative", paddingBottom: "24px" })

const ErrorMessage = styled(FormErrorText)({
    position: "absolute",
    bottom: "8px",
    left: "20px"
})

const TitleContainer = styled.div({
    display: "grid",
    rowGap: "8px",
    marginBottom: "24px",
    textAlign: "center"
})

const SuccessCard = styled(FormCard)({
    display: "grid",
    rowGap: "20px",
    width: "300px",
    padding: "45px 20px 30px 20px",
})

export default ForgotPasswordForm;
