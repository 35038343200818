import React, { useState, useEffect, useRef, useMemo, useContext } from "react"
import styled from 'styled-components'
import Table from "../../../core/table/Table"
import TableWrapper from "../../../core/table/TableWrapper"
import { LockModel } from "../../../models/lockModel"
import { EditLockForm } from "../../../pages/serial-codes/components/EditLockForm"
import { BladePopout } from "../../../core/layout/BladePopout"
import { ILockModelForm } from "../../../models/ILockModelForm";
import LockStatus from "../../../enums/LockStatus"
import SearchField from "../../../core/form-components/SearchField"
import { strings } from "../../../content/strings";
import { useLoggedInUser, useMediaQuery, useLocksTable, useLocks } from "../../../hooks/hooks";
import Role from "../../../enums/Role";
import { DownloadExcel } from "./DownloadExcel";
import { PrintTableButton } from "./PrintTableButton";
import { SecondaryLink } from "../../../components/button"
import useAnalytics from "../../../hooks/useAnalytics";
import { events as analyticsEvents } from "../../../mixPanelEvents";
import TableTitle from "../../../core/table/TableTitle"
import { Box } from "@mui/material"
import Permission from "../../../enums/Permission"
import { ToastContext } from "../../../contexts/ToastProvider"
import { ToastType } from "../../../enums/ToastType"
import { IPermission } from "../../../models/IPermission"
import GenericLoader from "../../../core/surfaces/GenericLoader"

const SerialCodesTableContainer = () => {
    const { locks, fetchUpdatedLocks, pending } = useLocks();
    const [mobile] = useMediaQuery(414);
    const [filteredLocks, setFilteredLocks] = useState<LockModel[]>([]);
    const printTableRef = useRef();
    const { loggedInUser } = useLoggedInUser();
    const { displayToast } = useContext(ToastContext);

    const [selectedLock, setSelectedLock] = useState<LockModel | null>();
    const { trackClickEvent } = useAnalytics("Serial Codes", false)
    const tableProps = useLocksTable()

    const permissions: { [key: number]: IPermission } = loggedInUser?.permissions || {}

    const writePermissions = Permission.write(Permission.Locks, loggedInUser)

    const restrictedPersonnelCannotEdit = (lockModel: LockModel) =>
        loggedInUser?.roleID === Role["Restricted Personnel"] && lockModel.secondaryLockStatus == LockStatus["Tenant Using Lock"]

    const selectRow = async (lockModel: LockModel) => {
        if (!writePermissions) {
            displayToast({ type: ToastType.Info, text: strings.insufficientPermissions })
            return;
        }
        if (restrictedPersonnelCannotEdit(lockModel)) {
            return;
        }
        await trackClickEvent(analyticsEvents.showEditLock, { contextID: lockModel.serialCode })
        setSelectedLock(lockModel)
    }

    useEffect(() => {
        if (locks?.length && selectedLock) {
            const updatedLock = locks.find(l => l.lockID == selectedLock.lockID)
            if (JSON.stringify(updatedLock) !== JSON.stringify(selectedLock)) {
                setSelectedLock(updatedLock)
            }
        }
    }, [locks])

    useEffect(() => {
        if (selectedLock?.facilityID) {
            fetchUpdatedLocks();
        }
    }, [selectedLock])

    const locksWithFacilities = useMemo(() => locks.filter(l => l.facilityID), [locks]);

    const displayTitleAndInventoryButton = () => {
        // quick solution: no need to make use of hte hasInventory flag since there's only one company using this site currently
        if(Role.isSiteRole(loggedInUser?.roleID)) return null
        if (mobile) {
            return (
                <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
                    <TableTitle title={strings.locks} />
                    <SecondaryLink style={{ width: "135px", justifySelf: "start" }} link="/Company/Inventory">{strings.goToInventory}</SecondaryLink>
                </Box>
            )
        }
            return (
                <>
                    <TableTitle title={strings.locks} />
                    <SecondaryLink style={{ width: "135px", justifySelf: "start" }} link="/Company/Inventory">{strings.goToInventory}</SecondaryLink>
                </>)
        
    }

    const renderTable = () => {
        if (loggedInUser?.roleID === Role.Vendor) {
            return <Table
                ref={printTableRef}
                data={filteredLocks}
                {...tableProps}
            />
        }

        return <Table
            noDataMessage={pending ? <GenericLoader /> : !filteredLocks.length && "Could not find locks" }
            tooltip={writePermissions ? strings.clickToEdit : ""}
            canEdit={(row) => !restrictedPersonnelCannotEdit(row)}
            ref={printTableRef}
            data={filteredLocks}
            rowProps={{ onClick: selectRow }}
            selectedRow={selectedLock}
            {...tableProps}
        />
    }

    return (
        <Container>
            <ActionsContainer>
                {displayTitleAndInventoryButton()}
                <SearchField
                    data={locksWithFacilities || []}
                    searchKeys={["unitNumber", "serialCode"]}
                    searchLabel={strings.serialCodeOrUnit}
                    setFilterData={setFilteredLocks}
                    useFacilityAutoComplete
                    geolocate
                    fullWidth={mobile}
                />
                {!mobile &&
                    <>
                        <PrintTableButton printTableRef={printTableRef} trackClickEvent={trackClickEvent} style={{ justifySelf: "end" }} />
                        <DownloadExcel data={filteredLocks} trackClickEvent={trackClickEvent} inventory={false} />
                    </>

                }
            </ActionsContainer>

            <TableWrapper >
                {renderTable()}
            </TableWrapper>

            <BladePopout
                show={!!selectedLock}
                closeBlade={() => { setSelectedLock(null); }}>
                {selectedLock &&
                    <EditLockForm
                        closeBlade={() => { setSelectedLock(null); }}
                        initialValue={selectedLock as ILockModelForm}
                    />
                }
            </BladePopout>
        </Container >
    )
}

const ActionsContainer = styled.div({
    display: "grid",
    width: "100%",
    alignItems: "baseline",
    gridTemplateColumns: "minmax(135px, 1fr) repeat(4, auto)",
    "@media (max-width: 414px)": {
        gridTemplateColumns: "1fr",
        gap: "4px",
    },
})

const Container = styled.div({
    display: "grid",
    width: "100%",
    position: "relative",
    padding: "8px",
    boxSizing: "border-box",
})

export default SerialCodesTableContainer;
