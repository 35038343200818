import React, { createContext, useEffect, useState } from "react";
import agents from "../api/agent";
import environment from "../utils/environment";

export const CountryProvider = ({ children, }: any) => {
    const [country, setCountry] = useState(localStorage.getItem("country") || "");

    const setCountryAndStorage = (country: string) => {
        localStorage.setItem("country", country);
        setCountry(country)
    }

    useEffect(() => {
        const getCountry = async () => {
            if (environment.local) {
                setCountryAndStorage("USA");
            } else {
                const result = await agents.Account.getIpCountry();
                setCountryAndStorage(result?.data.countryCode || "USA");
            }
        };

        if (!country) {
            getCountry();
        }
    }, []);

    return (
        <CountryContext.Provider value={country}>
            {children}
        </CountryContext.Provider>
    );
};

export const CountryContext = createContext<string>("");
