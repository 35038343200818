import { useLoggedInUser } from "../hooks/useLoggedInUser";
import { Navigate, useLocation } from "react-router-dom";
import Role from "../enums/Role";
import { ProgressionSteps } from "../enums/ProgressionSteps"
import Permission from "../enums/Permission";
import { useRedirectToNewWebApp } from "./useRedirectToNewWebApp";
import { useContext } from "react";
import { ToastContext } from "../contexts/ToastProvider";
import { ToastType } from "../enums/ToastType";

const UnauthorizedRedirect = ({
    children,
    roles,
    permission
}: { children: JSX.Element, roles: number[] | null, permission?: Permission }) => {
    const { loggedInUser, loading } = useLoggedInUser();
    const shouldStayAtLegacySite = useRedirectToNewWebApp(loggedInUser);
    const location = useLocation();
    const { displayToast } = useContext(ToastContext)


    if (loading) return null

    if (!shouldStayAtLegacySite) return null;

    if (!loggedInUser) {
        return (
            <Navigate to="/Account/Login" replace />
        )
    }

    if (location.pathname !== '/Account/UpdatedTermsOfUse'
        && loggedInUser
        && !Role.isSiteRole(loggedInUser?.roleID)
        && !loggedInUser?.completedSteps?.includes(ProgressionSteps.TermsOfUse20230101)) {
        return (
            <Navigate to="/Account/UpdatedTermsOfUse" replace />
        )
    }

    if ((loggedInUser?.roleID || 0) >= 100 && permission && loggedInUser.permissions && (loggedInUser.permissions[permission]?.accessLevel || 0) < 1) {
        const link = Role.getDashLink(loggedInUser.roleID);

        return (
            <Navigate to={link.link!} replace />
        )
    }

    // TODO: Clean up these if statements
    if (roles && loggedInUser?.roleID && (!permission || loggedInUser.roleID < 100) && !roles.includes(loggedInUser?.roleID)) {
        const link = Role.getDashLink(loggedInUser.roleID);

        return (
            <Navigate to={link.link!} replace />
        )
    }
    if (location.pathname == "/SerialCodes_Locks/Reports" && loggedInUser.email?.includes("publicstorage")) {
        displayToast({type:ToastType.Warning, text:"Permission Denied"})
        return (
            < Navigate to="/Users/Dashboard" replace />
            )
    }

    return children
};

export default UnauthorizedRedirect;
