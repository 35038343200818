import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { PrimaryButton } from "../../components/button";
import { handleOktaResetPasswordForPublicStorage, handleOktaLoginForPublicStorage } from "../../hooks/useLogin"

interface IProps {
    redirectToOktaLogin: boolean,
    setRedirectToOktaLogin: () => void,
    login:boolean,
}
const OktaLoginDialog = ({ redirectToOktaLogin, setRedirectToOktaLogin, login }: IProps) => {
    const loginDisplayText = "It looks like you're with Public Storage. Access is managed through Okta. Click the button below to login to DaVinci using your Okta credentials";
    const forgotPasswordDisplayText = "It looks like you're with Public Storage. Access is managed through Okta. Click the button below to reset your Okta password";
    const submit = login ? "Login" : "Forgot Password"
    return (
        <Dialog
            open={redirectToOktaLogin}
            onClose={() => setRedirectToOktaLogin(false)}

        >
            <DialogTitle >
                {"Use Okta Login"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText >
                    {login ? loginDisplayText:forgotPasswordDisplayText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <PrimaryButton onClick={login ? handleOktaLoginForPublicStorage : handleOktaResetPasswordForPublicStorage } autoFocus>
                    {submit}
                </PrimaryButton>
            </DialogActions>
        </Dialog>);
}

export default OktaLoginDialog;
