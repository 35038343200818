// use this enum for managing selected fms state
export enum ManagementSoftware {
    "Not listed" = 0,
    storEDGE = 1,
    SiteLink = 2,
    "Easy Storage Solutions" = 3,
    // Cubesmart = 4,
    // Centershift = 5,
    DoorSwap = 6,
    // Syrasoft = 7,
    Hummingbird = 8,
    WebSelfStorage=10,
    SsmCloud=11,
    // "Storage Commander" = 9,
}
