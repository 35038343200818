export const strings = {
    accept: "ACCEPT",
    accountCreation: "Account Creation",
    activate: "Activate",
    active: "Active",
    activateLocks: "Follow these instructions to activate your new locks:",
    activateSerialCode: "Activate Serial Code / Lock",
    activateSerialCodes: "Activate Serial Codes / Locks",
    addAFacility: "Add A Facility",
    addApiKey: "Add API Key",
    addFacility: "Add Facility",
    addLocks: "Add Locks",
    address: "Address",
    addresses: "The information you provided corresponds to a number of addresses. Please select the correct address from below",
    addSubdomain: "Add Subdomain",
    addUser: "Add User",
    agreeToTerms: "I agree to DaVinci Lock",
    alertUnitAssignment: (delinquent: boolean) => `Unit doesn't appear to be ${delinquent ? "delinquent" : "vacant"}. Are you sure you want to assign this lock to it?`,
    alertMultipleLocks: "Unable to assign multiple locks to this unit.",
    all: "All",
    allFacilities: "All Facilities",
    and: "and",
    apiKey: "API Key",
    apiKeyGenerated: "Will be generated upon submit",
    apiSecret: "API Secret",
    apiSetting: "API Settings",
    apply: "Apply",
    areYouSure: "Are you sure?",
    assignedFacilities: "Assigned Facilities",
    assignedPersonnel: "Assigned Personnel",
    assignedOverlock: "Assigned Overlock",
    assignedVacant: "Assigned Vacant",
    assignToUnit: "Assigned to Unit Number",
    authenticate: "Authenticate",
    authenticationTitle: "Enter your password to change your security settings",
    autoMessages: "Auto Messages",
    autoLock: "Auto Lock Status Change",
    autoLockOverlockContent: "When the tenant receives an unlock code for an overlocked unit, change the status to:",
    autoLockVacantContent: "When the tenant receives an unlock code for a newly rented unit, change the status to:",
    available: "Available",
    back: "BACK",
    backToDash: "Back To Dashboard",
    bestLock: "DaVinci Lock®, the best locking solution for self storage.",
    buyLocks: "Buy Locks & Supplies",
    callCenterPage: "Call Center Agent",
    cancel: "Cancel",
    canNotFindLock: "A lock matching the entered serial code could be found.",
    changeEmail: "Change Email",
    changePassword: " Change Password",
    chooseColor: "Choose a Lock Color",
    chooseAddress: "Choose an Address:",
    city: "City",
    clear: "Clear",
    clickToEdit: "Click To Edit",
    clickToShow: "CLICK TO SHOW",
    clientAdmin: "Client Admin",
    combinationEditTooltip: "Lock must begin as Available",
    comingSoon: "Coming Soon...",
    companies: "Companies",
    companyCreation: "Create Your Account",
    companyDetails: "Company Details",
    companyName: "Company Name",
    companiesSearchLabel: "Company Name / Admin / Email",
    companyPublicName: "Public Company Name",
    companyPhoneNumber: "Company Phone Number",
    companyApiKey: "Company API Key",
    companyUrl: "Company Website URL",
    confirm: "CONFIRM",
    confirmEmail: "Confirm Email",
    confirmPassword: "Confirm Password",
    contactDetails: "Contact Details",
    contactless: "The DaVinci Lock System enables contactless removal of locks",
    continue: "CONTINUE",
    continueChangingLock: "Continuing will set lock to Available. Continue?",
    copyright: `${new Date().getFullYear()} Patent: 10,475,115`,
    corpCode: "Corporate Code",
    corpPassword: "Corporate Password",
    corpUsername: "Corporate Username",
    continueEditing: "CONTINUE EDITING",
    createAccount: "CREATE ACCOUNT",
    createAccountLink: "Don't have an account? Register Here",
    createApiKey: "Create API Key",
    createCompanyIntro: "Let's start with your company name and website URL.",
    createCompanyTitle: "Create Company",
    createCustomRole: "CREATE CUSTOM ROLE",
    createRole: (type: number) => type === 1 ? "Create Role" : "Edit Role",
    createAccountCompletion: "Complete Account Creation",
    createdOn: "Created On",
    csvInstructions: [
        "If you are using SiteLink or storEDGE, click 'USE CSV' to get to the drop zone area.",
        "At the bottom of the page you'll find a button titled: Download Template",
        "Fill the template with the required information corresponding to the FMS you are using found on the table below.",
        "Click the drop zone area to import the file!"
    ],
    csvPopupNotes: [
        "Fields marked with * are required",
        "Street information should be four or more characters long",
    ],
    continueAccountCreation: "It looks like you've already started creating an account. Do you want to continue where you left off or start over?",
    customerServicePhone: "Customer Service Phone Number",
    customRoles: "Custom Roles",
    customMessage: "Custom Message",
    damaged: "Damaged",
    dashboard: "Dashboard",
    davinci: "DaVinci Lock",
    davinciUsers: "DaVinci Users",
    davincilockPageDescription: "The DaVinci Lock System allows for automated and contactless lock removal. The system uses a standard combination lock with an encrypted serial code engraved on it. Using DaVinci software, serial codes can be decrypted to retrieve lock combinations. Authorized employees can provide lock combinations to tenants.",
    davinciLockPremium: "DaVinci Lock Premium",
    decline: "DECLINE",
    defaultRoles: "Default Roles",
    deleteCustomRole: "DELETE CUSTOM ROLE",
    description: "Description",
    disabled: "Disabled",
    disclaimer: "Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries and regions. App Store is a service mark of Apple Inc. Google Play and the Google Play logo are trademarks of Google LLC.",
    displayName: "Display Name",
    downloadParagraph: "Fulfill your customers requests on the go with our app for iOS and Android.",
    doYouUseAnFMS: "Do you use a facility management software?",
    downloadTitle: "Download the App",
    downloadTemplate: "Download Template",
    edit: "Edit",
    editApiKey: "Edit API Key",
    editCompany: "Edit Company",
    editEmail: " Edit Email",
    editFacility: (facilityName: string) => `Edit Facility: ${facilityName}`,
    editToShow: "Edit to show",
    editSubdomain: "Edit Subdomain",
    editUser: "Edit User",
    emailInvalidOrInUse: "Email is invalid or in use",
    email: "Email Address",
    emailLabel: "email",
    emailLabeRequired: "Email Address*",
    enterCode: "Enter the serial code of the lock you have.",
    entityNumber: "Entity Number",
    expiredFacilityTransfer: "Transfer request expired.",
    expiredLink: "Your link has expired.",
    expiredToken: "The token provided is expired or invalid.",
    exportToExcel: "Export to Excel",
    facilities: "Facilities",
    facility: "Facility",
    facilityAPIKey: "Facility Api Key",
    facilityGdsId: "Facility GDS ID",
    facilityBrandName: "Public Facility Name",
    facilityID: "Facility ID",
    facilityListing: "Facility Listing",
    facilityMngmtSoft: "Facility Management Software",
    facilityName: "Facility Name",
    facilityNameOrId: "Facility Name or ID",
    facilityNameOrNumber: "Facility Name or Number",
    facilityStatus: "Facility Status",
    facilityTransfer: "Facility Transfer",
    facilitiesSuccess: "Updated Facilities Successfully",
    facilityTransferApproved: "Facility transfer approved.",
    facilityTransferInitiated: "Facility transfer initiated.",
    facilityTransferString: "would like to transfer the following facility to your account:",
    facilityUrl: "Facility URL",
    firstName: "First Name",
    fmsSubText: {
        doorSwap: "This information can be obtained by contacting DoorSwap Support. You will also need to provide DaVinci Lock API information to DoorSwap.",
        easyStorage: "This information can be obtained by contacting Easy Storage Solutions Support.",
        hummingBird: "The GDS facility ID can be found in the FMS Initial Setup tab in Tenant Inc “Website” portal settings. Contact Hummingbird Support if you don't have access to Tenant Inc “Website”.",
        siteLink: "This information can be obtained from SiteLink Corporate Control Center.",
        storeEDGE: "This information can be obtained from the storEDGE FMS.",
        webSelfStorage: "This information can be obtained from WebSelfStorage",
        selfStorageManager: "This information can be obtained from SSM."
    },
    fms: {
        fms: "Facility Management Software",
        doorSwap: "DoorSwap",
        easyStorage: "Easy Storage Solutions",
        siteLink: "SiteLink",
        storeEDGE: "storEDGE",
        HummingBird: "Hummingbird",
        selfStorageManager: "Self Storage Manager",
        other: "Other",
    },
    forgotPassword: "Forgot Password",
    freeSerialCodes: "Free Serial Codes",
    getStarted: "GET STARTED",
    getPremium: "GET PREMIUM",
    gettingStartedTitle: "Davinci Lock- GET STARTED",
    gettingStartedTitleDescription: "",
    gettingStartedTitleCanonical: "https://www.davincilock.com/Account/GettingStarted",
    goToInventory: "Go To Inventory",
    goToActiveLocks: "Go To Active Locks",
    help: "Have additional questions?",
    HIWTwo: "Here's how it works:",
    home: "Home",
    howItWorks: "HOW IT WORKS",
    howItWorksPageCanonical: "https://www.davincilock.com/Account/HowItWorks",
    howItWorksPageDescription: "The DaVinci Lock System allows for automated and contactless lock removal. The system uses a standard combination lock with an encrypted serial code engraved on it. Using DaVinci software, serial codes can be decrypted to retrieve lock combinations. Authorized employees can provide lock combinations to tenants.",
    howItWorksPageTitle: "Davinci Lock - How it Works",
    howToVids: "How To Videos",
    importFacilities: "IMPORT FACILITIES",
    inactive: "Inactive",
    incompleteInformation: "Incomplete Information",
    includeInApi: "include in API",
    integrateFacilities: "Integrating facilities with a CSV?",
    integrations: "Integrations",
    integrationDescription: "Integrations Page",
    initiate: "Initiate",
    insufficientPermissions: "Insufficient Permissions",
    insertName: "Insert Name",
    insertFacility: "Insert Facility",
    insertCompany: "Insert Company",
    insertUnlockCodes: "Insert Unlock Codes",
    inventory: "Inventory",
    itemsPerPage: "items per page",
    itemsTotal: (total: number) => `Total: ${total}`,
    jobs: "Jobs",
    joinCustomers: "Join Our Other Customers Using DaVinci Lock",
    key: "key",
    landingPageCanonical: "https://www.davincilock.com/",
    landingPageDescription: "The DaVinci Lock System allows for automated and contactless lock removal. The system uses a standard combination lock with an encrypted serial code engraved on it. Using DaVinci software, serial codes can be decrypted to retrieve lock combinations. Authorized employees can provide lock combinations to tenants.",
    landingPageTitle: "DaVinci Lock - Home",
    lastName: "Last Name",
    lat: "Latitude",
    lng: "Longitude",
    learnMore: "LEARN MORE",
    loading: "Loading...",
    locationCode: "Location Code",
    lockManufacturer: "Manufacturer of the Lock",
    locksGenerated: "Locks generated",
    locks: "Locks",
    lockNotAvailable: "Lock is not set to Available",
    lockSystem: "The DaVinci Lock System allows for automated and contactless lock removal. The system uses a standard combination lock with an encrypted serial code engraved on it. Using DaVinci software, serial codes can be decrypted to retrieve lock combinations. Authorized employees can provide lock combinations to tenants.",
    lockTransferCode: (serialCode: string) => `Select receiving facility for Serial Code ${serialCode} :`,
    login: "Login",
    logout: "Log out",
    logoutRedux: "LOGOUT",
    logs: "Logs",
    logsPage: "Logs Page",
    lost: "Lost",
    markLock: (status: string) => `${status === "returned" ? " Returned To Dropbox" : "Tenant Using Lock"}`,
    managementSoftware: "Management Software",
    marketingMaterial: "Today, tenants demand instant service. Helping customers quickly is good for business. With DaVinci Lock, authorized employees can provide lock combinations remotely. This helps customers, saves time, and increases satisfaction.",
    moreInfo: "More Info",
    mustBeValidEmail: "Must be a valid Email.",
    mustBeValidPhoneNumber: "Must be a valid Phone Number.",
    mustSelectAdmin: "Must select admin",
    name: "name",
    nameLabel: "Name",
    nameOrKey: "Name or Key",
    nameUpperCase: "Name",
    navText: "Need help? Email us at support@davincilock.com today!",
    needOneFacility: "You need at least one facility before you can do this",
    needOneLock: "You need to activate at least one lock before you can do this",
    newEmail: "New Email",
    newPassword: "New Password",
    no: "NO",
    noContracts: "There are no contracts or any recurring fees to use DaVinci Lock.",
    noGpsInfo: "No GPS Information",
    noLocksFound: "We found no locks in your dropbox",
    noOfActiveLocks: "# Active Locks",
    noOfFacilities: "# of Facilities",
    noOfUnits: "Number of Units",
    nothingHasChanged: "Nothing has changed.",
    none: "None",
    nonRequiredField: "Non-Required Field",
    numOfLocks: "Number of Locks to be added",
    ok: "OK",
    overlockUnits: "Overlocked Units",
    password: "Password",
    baseUrl: "baseUrl",
    passwordResetLinkConfirmation: "If your account exists, an email has been sent with a link to reset your password.",
    passwordsMustMatch: "Passwords must match.",
    permissions: "Permissions",
    permissionID: "Permission ID",
    permissionName: "Permission name",
    permissionRoleUpdate: "Your user permissions have been updated. Data retrieved",
    permissionUserUpdate: "Your user has been updated. Data retrieved",
    phone: "Phone",
    phoneNumber: "Phone Number",
    phoneNumberRequired: "Phone Number*",
    pin: "Pin",
    pleaseContinueEditing: "Please continue editing",
    previousUnit: "Previous Unit",
    print: "Print",
    printAlert: (length: number) => `You are trying to print ${length} rows. Print preview could take some time to load.`,
    privacyPolicy: "Privacy Policy",
    proceedToCreateAccount: "Proceed to Sign in",
    proceedToSignIn: "Proceed to Sign in",
    processLockReturns: "Process Lock Returns",
    processReturned: "Process Returned Locks",
    profile: "Profile",
    publicFacing: "Public Facing",
    publicName: "Public Facility Name",
    quickSerialLookup: "Quick Serial Lookup",
    quickUnitLookup: "Quick Unit Lookup",
    ready: "Interested in a consultation?",
    recipient: "Recipient",
    recipientEmail: "Recipient's Email",
    refresh: "Refresh",
    refreshUnits: "Refresh Unit Cache",
    registrationSuccess: "Your account has been created. To set your password click the link in the email sent to you.",
    reports: "Reports",
    requestDemo: "REQUEST A DEMO",
    requestLock: "REQUEST A DEMO LOCK",
    required: (field: string) => `${field} is required.`,
    requiredLabel: "Fields marked with * are required",
    requiredManagementSoftware: "You must choose a management software.",
    usingManagementSoftwareIsRequiredFor: "Using a management software is required for automated access.",
    resetPassword: "Reset Password",
    resetToDefault: "Reset To Default",
    restrictOneLockperUnit: "Limit one lock per unit",
    returnedStatus: "Returned status was removed from",
    requiredField: "Required Field",
    role: "Role",
    roleDisplayName: "Role Display Name",
    rolesSettings: "Roles Settings",
    rolesSettingsDescription: "Roles Settings Description",
    rolesAndPermissions: "Role & Permissions",
    rolesAndPermissionsDescription: "Role & Permissions Page",
    save: "Save",
    saveChanges: "SAVE CHANGES",
    saveProfile: " Update Profile",
    search: "Search",
    searchForAssigned: "Search for assigned",
    searchForUnassigned: "Search for unassigned",
    secure: "SECURE VACANTS",
    select: "Select",
    selectFms: "Select an FMS",
    selectFacility: "Select the facility you'd like to assign a lock to.",
    selectFacilityLabel: "Select Facility",
    selectStatus: "Select Status",
    selectUserLabel: "Select User",
    selectUserRoleLabel: "Select Role",
    sendEmail: "SEND EMAIL",
    sendAutoNotifications: "Send Auto Notifications",
    serialCode: "Serial Code",
    serialCodesPageTitle: "Serial Codes",
    serialCodeOrUnit: "Serial Code or Unit",
    serialCodePrintHeader: `Serial  Code Listing as of ${new Date().toString().replace(/:\d\d\s.*/, "")}`,
    serialCodeDetails: "Serial Code Details",
    serialCodes: "Serial Codes / Locks",
    setPasswordSuccess: "Password set successfully.",
    settings: "Settings",
    shortNavText: "",
    shortNoOfUnits: "No of units",
    signin: "SIGN IN",
    signinOrCreateAccount: "LOG IN / CREATE AN ACCOUNT",
    signinPageCanonical: "https://www.davincilock.com/Account/Login",
    signinPageTitle: "Log in",
    slogan: "Automated access made easy and reliable",
    softwareIntegration: "The DaVinci Lock is not just another lock. The DaVinci Lock System’s power comes when integrated with your facility management software. When integrated with your facility management software, DaVinci Lock provides lock combinations to your customers automatically.",
    softwareIntegrationHeader: "Management Software Integration",
    somethingWentWrong: "Something went wrong.",
    somethingWentWrongLong: "Something went wrong on our end",
    sorry: "Sorry!",
    startOver: "START OVER",
    state: "State/Province/Region",
    status: "Status",
    store: "STORE",
    storeEDGEPopup: [
        "Log into you storEDGE account",
        "click on CORPORATE on the top right corner",
        "The url will change to something like this: https://enterprise.storedge.com/company/4964c703-e8a0-4e3a-8ecb-123124-dfd/facilities",
        "Copy the code between company/ and /facilities"
    ],
    siteLinkPopup: [
        "Fill the form below with the required information",
        "Note: SiteLink will import only the facilities that have address information"
    ],
    street: "Street",
    streetValidation: "Street must be between 4 and 60 characters.",
    subdomain: "Subdomain",
    subdomains: "Subdomains",
    subdomainUrl: "Subdomain URL",
    subdomainUrlRegex: "Allowed: a-z, 0-9, and hyphen, but not at start or end.",
    subdomainUpdateFailed: "Subdomain failed to update",
    subdomainUpdated: "Subdomain updated",
    subdomainLogoUpdated: "Subdomain Logo updated",
    subdomainLogoUpdateFailed: "Failed to update subdomain logo ",
    submit: "SUBMIT",
    support: "SUPPORT",
    supportPage: "Visit the DaVinci Lock support page to review frequently asked questions or reach our support team.",
    sync: "Sync",
    syncUnits: "SYNC UNITS",
    tableFilter: "Show items that \n contain:",
    tableTooltip: "Click To Edit",
    tapButton: "Tap the Activate button.",
    tenantUsing: "Tenant Using",
    termsOfUseUpdated: "We have updated our Terms of Use",
    termsOfUse: "Terms of Use",
    text: "text",
    thankYou: "Thank You",
    thankYouSubText: "A member of our team will reach out to you shortly!",
    thankYouTitle: "Davinci Lock- Thank You",
    thankYouTitleDescription: "",
    thankYouTitleCanonical: "https://www.davincilock.com/Account/ThankYou",
    theAddressProvided: "We were able to find a match for your address",
    toggleStatus: "Account Access",
    transferApproval: 'Approval for a facility transfer can only be given by a client admin. Select a client admin to forward this request to.',
    transferFacility: "Transfer Facility",
    transferFacilityPage: "Transfer Facility",
    transferFacilitySuccess: "Facility transfer initiated.",
    transferLock: "TRANSFER LOCK TO FACILITY",
    transferLockHeader: "Transfer Lock",
    transferTheFacility: "Transfer This facility",
    transferOwnership: (size: string) => size == "lg" ? "TRANSFER OWNERSHIP" : "Transfer Ownership",
    unitNumber: "Unit Number",
    unlockCode: "Unlock Code",
    unlockOrSerial: "unlock code or serial code",
    unverifiedAddresses: (num: number) => `${num} facilities addresses could not be verified`,
    updatedBy: "Updated By",
    updatedOn: "Updated On",
    updatedStatus: "was updated as returned",
    updateOneLock: "Must Update at least one lock status",
    updateUser: "Must select a user.",
    userToggle: (status: boolean) => status ? 'User Deactivated.' : 'User Activated.',
    userFacilities: "User Facilities",
    username: "Username",
    usernameOrEmail: "User Name or Email",
    userProfile: "User Profile",
    users: "Users",
    useApi: "Use API Key",
    useCSV: "Use CSV",
    weAreNotified: "We've been notified and are working hard to address this.",
    userStatusToggle: (status: boolean) => status ? "Deactivate User?" : "Activate User",
    max: (field: string, length: number) => `${field} cannot be more than ${length} characters.`,
    min: (field: string, length: number) => `${field} must be at least ${length} characters.`,
    vacantUnits: "Vacant Units",
    validators: {
        corporate: "Corporate Code",
        emailMatch: "Emails must match.",
        emailReq: "Email is required",
        facilityID: "Facility ID",
        mustBeNum: "Must be a number",
        mustBeVaildEmail: "Must be a valid Email.",
        serialCodelength: "Serial Code must be 8 digits.",
        unlockCodelength: "Unlock Code must be 4 digits.",
        unlockCodeReq: "Unlock Code is required.",
        userName: "User Name",
        userRole: "A user role is required.",
        locationCode: "Location Code",
        facilityTransfer: {
            key: "Custome Test",
            validation: "Cannot transfer facility to the same company"
        },
        uniqueEmail: "Email already in use"
    },
    vendor: "Vendor",
    view: "View",
    viewLogs: "VIEW LOGS",
    viewUsers: "VIEW USERS",
    whatIsCompanyAddress: "What is your company address?",
    whatIsYourEmail: "What is your email?",
    whatIsYourCompanyPhone: "What is your company phone number?",
    whatIsYourFacilityAddress: "What is your facility address?",
    whatIsYourFacilityBrand: "How is your facility publicly known and what is its website URL?",
    whatIsYourFacilityIdentifier: "What name do you use to identify your facility?",
    whatIsYourName: "What is your name?",
    whatIsYourPassword: "What is your password?",
    whatIsYourFMS: "Which management software do you use?",
    weCantVerifyAddress: "We could not verify the address you have provided",
    yes: "YES",
    yourAddress: "Your Address:",
    managmentSoftwareInfo: "Management software information is required for automated access.",
    zip: "ZIP/Postal Code",
    selectedFacility: (include: boolean) => include ? "Exclude Facility from API?" : "Include Facility in API?"
}
