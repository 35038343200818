import { getAuthError, getAuthPending, loginAsync } from "../redux/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../hooks/useAppSelector";
import { IAccount } from "../models/IAccount";
import { useCallback, useContext, useState } from "react";
import agents from "../api/agent";
import { ToastContext } from "../contexts/ToastProvider";
import { ToastType } from "../enums/ToastType";

interface IUseLogin {
    login: (account: IAccount['login']) => void,
    errorMessage: string | undefined,
    pending: boolean | undefined,
    redirectToOktaLogin: boolean,
    setRedirectToOktaLogin: any
}

interface DomainMap {
    [key: string]: string;
}

export const domainMap: DomainMap = {
    publicStorage: "@publicstorage.com",
};

export const isEmailInDomainMap = (email: string): boolean => {
    const emailDomain = email.split('@')[1];
    for (const domain in domainMap) {
        if (domainMap[domain] === `@${emailDomain}`) return true;
    }
    return false;
}

const navigateToExternalUrl = (url: string) => {
    window.location.href = url;
};

export const handleOktaLoginForPublicStorage = () => {
    navigateToExternalUrl("https://publicstorage.okta.com/login/default");
}
export const handleOktaResetPasswordForPublicStorage = () => {
    navigateToExternalUrl("https://publicstorage.okta.com/signin/forgot-password");
}

export const useLogin = (): IUseLogin => {
    const dispatch = useAppDispatch()
    const error = useAppSelector(getAuthError)
    const pending = useAppSelector(getAuthPending)
    const { displayToast } = useContext(ToastContext);


    const [redirectToOktaLogin, setRedirectToOktaLogin] = useState(false);

    const login = useCallback(async (account: IAccount['login']) => {
        const { email } = account;
        const isOktaLogin = isEmailInDomainMap(email);
        if (isOktaLogin) {
            try {
                const response = await agents.Auth.getUserByEmail(email);
                if (response.success) {
                    response.data.sso ? setRedirectToOktaLogin(true) : 
                        dispatch(loginAsync(account)) 
                } else {
                    displayToast({type:ToastType.Error,text: response.error || "something went wrong"})
                }
            } catch (e) {

                console.log({e})
            }
        } else {
            dispatch(loginAsync(account));
        }
    }, [])

    return {
        login,
        errorMessage: error,
        pending,
        redirectToOktaLogin,
        setRedirectToOktaLogin
    }
}
