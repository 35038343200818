import React, { useContext, useState } from "react";
import agents from "../../../api/agent";
import { PrimaryButton, QuaternaryButton } from "../../../components/button";
import { ToastContext } from "../../../contexts/ToastProvider";
import { ToastType } from "../../../enums/ToastType";
import { Dialog, Switch } from "@mui/material/";
import { PBold } from "../../../core/brand/typography";
import { strings } from "../../../content/strings";
import { SwitchInput, CenteredSpan, DialogContainer, ButtonsSpan } from "./AddEditUserForm";
import { ICompanyUser } from "../../../models/ICompanyUser";

export const ToggleUserStatus = ({
    initialValue, user, closeBlade
}: { initialValue: boolean; user: ICompanyUser; closeBlade: () => void; }) => {
    const { displayToast } = useContext(ToastContext);
    const [toggleUserCheck, setToggleUserCheck] = useState(initialValue);
    const [canDeactivateuser, setCanDeactivateUser] = useState(true);

    const userCheckHandler = () => {
        setToggleUserCheck(!toggleUserCheck);
        setCanDeactivateUser(!canDeactivateuser);
    };

    const handleCancellation = () => {
        setToggleUserCheck(initialValue);
        DialogHandler();
    };

    const DialogHandler = () => {
        setCanDeactivateUser(true);
    };

    const handleToggleUserDeactivate = async () => {
        try {
            const result = await agents.Users.updateUser({
                ...user,
                isActive: !user.isActive,
                userInRole: user.roleID
            });
            if (result.success) {
                displayToast({ type: ToastType.Success, text: strings.userToggle(initialValue) });
                setToggleUserCheck(!toggleUserCheck);
                DialogHandler();
                closeBlade();
            }
            else {
                displayToast({ type: ToastType.Error, text: result.error || strings.somethingWentWrong });
            }
        }
        catch (e) {
            if (e instanceof Error) {
                displayToast({ type: ToastType.Error, text: e.message || "" });
            }
        }
    };

    return (
        <>
            <SwitchInput justify="flex-end">
                <CenteredSpan>
                    <Switch
                        checked={toggleUserCheck}
                        onChange={userCheckHandler} />
                </CenteredSpan>
                <PBold style={{ marginBlockEnd: "0px" }}>{strings.toggleStatus}</PBold>
            </SwitchInput>
            {/* I know this is not ideal but using styled does not wrok here! */}
            <Dialog open={!canDeactivateuser}
                onClose={handleCancellation}
                sx={{
                    position: "absolute", bottom: "150px", left: "76%",
                    "@media(max-width:1445px)": {
                        left: "68%"
                    },
                    "@media(max-width:1165px)": {
                        left: "55%"
                    },
                    "@media(max-width:800px)": {
                        left: "35%"
                    },
                    "@media(max-width:500px)": {
                        left: "0"
                    },
                }}>
                <DialogContainer>
                    <PBold>{strings.userStatusToggle(initialValue)}</PBold>
                    <ButtonsSpan>
                        <QuaternaryButton fullWidth onClick={handleCancellation}>{strings.cancel}</QuaternaryButton>
                        <PrimaryButton fullWidth onClick={handleToggleUserDeactivate}>{strings.submit}</PrimaryButton>
                    </ButtonsSpan>
                </DialogContainer>

            </Dialog>
        </>
    );
};
