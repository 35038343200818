import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/useAppSelector";
import { ILockModelForm } from "../models/ILockModelForm";
import { getLocks, fetchLocksAsync, fetchUpdatedLocksAsync, updateLock, fetchUpdatedCompanyLocksAsync, fetchCompanyLocksAsync, getInventoryLocks, getLocksPending } from "../redux/locks/lockSlice";
import { useCompanyID } from "./useCompanyID";

export const useLocks = () => {
    const { companyIDRef } = useCompanyID()
    const dispatch = useAppDispatch();
    const { locks } = useAppSelector(getLocks);
    const inventoryLocks = useAppSelector(getInventoryLocks);
    const pending = useAppSelector(getLocksPending)

    const fetchLocks = useCallback((inventory = false, facility = true) => {
        if (companyIDRef.current) {
            dispatch(fetchCompanyLocksAsync({ companyID: companyIDRef.current, inventoryLocks:inventory, facilityLocks:facility }))
        } else {
            dispatch(fetchLocksAsync({ inventoryLocks: inventory, facilityLocks: facility }))
        }
    }, []);

    const fetchUpdatedLocks = useCallback(() => {
        if (companyIDRef.current) {
            dispatch(fetchUpdatedCompanyLocksAsync({companyID:companyIDRef.current, inventoryLocks:false}))
        } else {
            dispatch(fetchUpdatedLocksAsync({inventoryLocks:false}))
        }
    }, []);

    const updateLockFromClient = (lockModel: ILockModelForm) => {
        const updatedLocks = locks?.map((l: any) => l.lockID === lockModel.lockID ? { ...l, ...lockModel } : l);
        dispatch(updateLock(updatedLocks))
    }

    const getFacilityLocks = (facilityID: number) => {
        const facilityLocks = locks?.filter((l: any) => l.facilityID == facilityID);
        return facilityLocks;
    }

    return {
        locks,
        fetchLocks,
        fetchUpdatedLocks,
        updateLockFromClient,
        getFacilityLocks,
        inventoryLocks,
        pending
    }
}
