import { useCallback, useEffect, useState } from "react";
import agent from "../api/agent";
import { ICompanyDetails } from "../models/ICompanyDetails";
import { RootState } from "../redux/store";
import { strings } from "../content/strings";
import { useAppSelector, useAppDispatch } from "./useAppSelector";
import { fetchCompaniesAsync } from "../redux/companies/companiesSlice";

const getCompaniesFromState = (state: RootState) => state.companies

export const useCompanies = () => {
    const dispatch = useAppDispatch();

    const companies = useAppSelector(getCompaniesFromState);
    const fetchCompanies = useCallback(() => {
        dispatch(fetchCompaniesAsync())
        
    },[])

    return {  ...companies, fetchCompanies}
}

export const useCompany = (companyID: number) => {
    const [company, setCompany] = useState<ICompanyDetails | null>(null)
    const [error, setError] = useState("")
    const { companies } = useCompanies();

    const fetchCompanyByID = useCallback(async () => {
        const result = await agent.Company.getCompanyByID(companyID);

        if (result.success) {
            setCompany(result?.data || null)
            setError("")
        } else {
            setError(result.error || strings.somethingWentWrong)
        }
    }, [companyID])

    useEffect(() => {
        if (companyID) {
            fetchCompanyByID();
        }
    }, [companyID])

    /** Return the company info we have until the details are fetched */
    return {
        company: company || companies.find(company => company.companyID === companyID),
        error,
        fetchCompanyByID,
    }
}
